:root {
    --primary: #5932EA;
}

.auth-wrapper-inner.is-single {
    background: #f5f6fa;
    min-height: 100vh;
}

.auth-wrapper-inner.is-single, .project-card .card-head {
    background: url(../images/bg-login.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.auth-wrapper-inner {
    overflow: hidden !important;
    height: 100%;
    padding: 0;
    margin: 0;
}

.auth-wrapper-inner .single-form-wrap {
    min-height: 690px;
    padding: 0 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.auth-wrapper-inner .single-form-wrap .inner-wrap {
    width: 100%;
    max-width: 400px;
    margin: auto;
}

.is-flex.is-flex-direction-column {
    flex-direction: column;
}

.auth-wrapper-inner .single-form-wrap .inner-wrap .logo {
    margin-top: 100px;
    margin-bottom: 24px;
    display: flex;
}

.auth-wrapper-inner .single-form-wrap .inner-wrap .image {
    margin-left: 100px;
    width: 60px;
    object-fit: contain;
    align-self: flex-start;
}
.auth-wrapper-inner .single-form-wrap .inner-wrap .title {
    display: inline-block;
    margin-left: 10px;
}

.auth-wrapper-inner .single-form-wrap .inner-wrap .form-card {
    background: #fff;
    border: 1px solid #B5B7C0;
    border-radius: 10px;
    padding: 24px;
    margin-bottom: 16px;
    color: #444444;
}

/* Row */
.row{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
}
.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
}

/* Modal */
.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y)* .5) calc(var(--bs-modal-header-padding-x)* .5);
    margin: calc(-.5* var(--bs-modal-header-padding-y)) calc(-.5* var(--bs-modal-header-padding-x)) calc(-.5* var(--bs-modal-header-padding-y)) auto;
}
.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75;
}
.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: .5
}

.badge.bg-primary-light {
    background-color: #ffeae6;
    color: #FF6D4D;
    border: 1px solid #FF6D4D;
}
.badge.bg-success-light {
    color: #2BC155;
    border: 1px solid #2BC155;
    background-color: #e3f9e9 !important; }
.badge.bg-danger-light {
    background-color: #fff3f7;
    color: #F94687;
    border: 1px solid #F94687;
}
.badge.bg-warning-light {
    background-color: #ffeae6;
    color: #FF6D4D;
    border: 1px solid #FF6D4D;
}
.badge-sm {
    font-size: 11px;
    padding: 5px 8px;
    line-height: 11px;
}
.badge-lg {
    font-size: 14px;
    padding: 0px 10px;
    line-height: 30px;
}
.btn.circle {
    border-radius: 1.25rem;
    padding: 0px;
}

.custom-badge {
    line-height: 1.5;
    border-radius: 4.25rem;
    font-size: 12px;
    font-weight: 600;
    padding: 0px 10px;
    border: 1px solid transparent;
    background-color: white;
}

.custom-circle {
    height: 50px;
    width: 50px;
    content: "";
    position: absolute;
    border-radius: 100%;
    top: 30px;
    left: 40px;
    transform: translate(-50%, -50%);
}

.custom-circle .icon {
    position: absolute;
    border-radius: 100%;
    top: 25px;
    left: 25px;
    transform: translate(-50%, -50%);
}

.order-circle {
    height: 50px;
    width: 50px;
    content: "";
    position: absolute;
    border-radius: 100%;
    top: 62px;
    left: 62px;
    transform: translate(-50%, -50%);
}

.order-circle .icon {
    position: absolute;
    border-radius: 100%;
    top: 25px;
    left: 25px;
    transform: translate(-50%, -50%);
}

.card.order {
    border: 1px solid #dee2e6;
    box-shadow: 0px 12px 23px 0px rgb(120 130 140 / 26%);
}

.card.order.warning {
    background-color: #FFF0CF;
}

/* .card.order .btn-warning {
    color: #fff;
    background-color: #866B1E;
    border-color: #866B1E;
  }
  .card.order .btn-warning:hover {
    color: #fff;
    background-color: #624b0e;
    border-color: #624b0e;
  }
  .card.order .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 109, 77, 0.5);
  } */
/* .card.order > .card-body > .badge-warning.bg-warning {
    color: #fff;
    background-color: #866B1E;
    border-color: #866B1E;
  }
.card.order .badge-warning {
    color: #fff;
    background-color: #866B1E;
    border-color: #866B1E;
  } */

  /* .card.order.success-light {
    color: #2BC155;
    border: 1px solid #2BC155;
    background-color: #e3f9e9 !important; }
    .card.order.danger-light {
    background-color: #fff3f7;
    color: #F94687;
    border: 1px solid #F94687;
} */
/* .card.order-tenant.order-warning {
    background-color: #FFF0CF;
    border: 1px solid #fae8c2;
} */
/* .card.order.warning-light {
    background-color: #FFF0CF;
    border: 1px solid #fae8c2;
} */


/* Button */
.btn {
    border-radius: .5rem;
    padding: 0.538rem 1.5rem;
}

.btn-icon{
    padding: 0.6rem 0.85rem;
}

/* Form control */
.form-control {
    border-radius:0.5rem;
    color: #000
}

.foodcourt-schedule-header {
    border-radius: 0.5rem;
    padding: 12px;
}

.nav-tabs .nav-link.active .badge {
    background-color: white;
    color: var(--primary);
}
.nav-tabs .nav-link .badge {
    background-color: #444444;
    color: white;
}
.nav-tabs .nav-link:hover .badge, .nav-tabs .nav-link:focus .badge {
    background-color: var(--primary);
    color: white;
}
.nav-tabs .nav-link.active:hover .badge, .nav-tabs .nav-link.active:focus .badge {
    background-color: white;
    color: var(--primary);
}

.nav-tabs .nav-item .nav-link {
    /* min-height: 48px; */
    width: 100%;
    height: 100%;
}

.rdt_TableCol div, .rdt_TableCell div {
    white-space: normal !important;
    overflow: visible !important;
}

.rounded-sm {
    border-radius: 8px !important;
}

.search-area-pre .input-group-text {
    background: #F9FBFF !important;
    border-top: 1px solid #B5B7C0;
    border-left: 1px solid #B5B7C0;
    border-bottom: 1px solid #B5B7C0;
    border-right: 0px;
    padding-left: 10;
    padding-right: 10px;
}

.search-area-pre .form-control {
    background: #F9FBFF !important;
    border-top: 1px solid #B5B7C0;
    border-right: 1px solid #B5B7C0;
    border-bottom: 1px solid #B5B7C0;
    border-left: 0px;
    padding-left: 0px;
    min-height: 44px;
    border-radius: 0;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
}

/* Bug fixed display dropdown in datatable after searching */
.rdt_Table {
    margin-bottom:50px;
}

/* Bug fixed display input date on iphone */
input[type="date"]
{
    display:block;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    line-height: 2;
}